import React from "react";
import styled from "styled-components";

import { InterpretationIconType } from "ts/enums/interpretationIconType";
import { SentimentType } from "@explorance/mly-types";

import { getCommentInterpretationIcon } from "common-layouts/CommentResultsIcons/helpers";
import { Text } from "components/Text";
import { SentimentPill } from "components/_pills/SentimentPill";

type Props = {
  sentiment: SentimentType;
};

export const DominantSentiment = ({ sentiment }: Props) => {
  return (
    <StyledDominantSentimentContainer>
      <StyledIconContainer>
        {getCommentInterpretationIcon(InterpretationIconType.comment, sentiment)}
      </StyledIconContainer>

      <Text resource="quickAnalysis.interpretation.commentSentiment.part1" />
      <SentimentPill
        sentiment={sentiment}
        showSentimentName
        coloredBorder
        customStyles={{ margin: "0 4px" }}
      />
      <b>
        <Text resource="quickAnalysis.interpretation.commentSentiment.part2" />
      </b>
    </StyledDominantSentimentContainer>
  );
};

const StyledDominantSentimentContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`;

const StyledIconContainer = styled.span`
  margin-right: 5px;
  margin-top: 2px;
  display: flex;
  align-items: center;
`;
