import React from "react";
import { useSharingContext } from "context/SharingContext";
import { ShareStep } from "ts/enums/sharing";
import { UserSelection } from "./UserSelection";
import { PermissionFormContainer } from "./PermissionForm/PermissionFormContainer";
import styled from "styled-components";
import { Color } from "ts/enums/color";
import parseHTML from "html-react-parser";
import { useResource } from "hooks/useResource";
import { Text } from "components/Text";

const SHARE_STEP_NUMBERS = {
  [ShareStep.UserSelection]: 1,
  [ShareStep.PermissionSettings]: 2,
};

export const ShareTo = () => {
  const { sharingState } = useSharingContext();
  const { getResource } = useResource();

  const stepContent = {
    [ShareStep.UserSelection]: <UserSelection />,
    [ShareStep.PermissionSettings]: <PermissionFormContainer />,
  };

  return (
    <>
      <StyledSharingStepHeader>
        <span>
          {parseHTML(
            getResource(
              "sharing.stepHeader.step",
              SHARE_STEP_NUMBERS[sharingState.step],
              Object.keys(SHARE_STEP_NUMBERS).length
            )
          )}
        </span>
        <b>
          <Text
            resource={{
              key:
                sharingState.step === ShareStep.UserSelection
                  ? "sharing.stepHeader.description.selectUsers"
                  : "sharing.stepHeader.description.selectPermissionLevel",
              args: [
                getResource(
                  sharingState.selectedUsersCount === 1
                    ? "sharing.table.usersInGroup.cell.singular"
                    : "sharing.table.usersInGroup.cell",
                  sharingState.selectedUsersCount
                ),
              ],
            }}
          />
        </b>
      </StyledSharingStepHeader>
      {stepContent[sharingState.step]}
    </>
  );
};

const StyledSharingStepHeader = styled.div`
  color: ${Color.white};
  background-color: ${Color.gray50};
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 16px;
  display: flex;
  align-items: center;
`;
