import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { LoadingDots } from "components/LoadingDots";
import { Button } from "components/_buttons/Button";
import { CategorizationTypeSymbol } from "components/_icons/CategorizationTypeSymbol";
import { Icon, IconType } from "components/_icons/Icon";
import { FileUploadInput } from "components/_inputs/FileUpload";
import { Tooltip } from "components/Tooltip";

import { useTimeStamp } from "hooks/useTimeStamp";
import { routes } from "routes";
import { deleteAnalysis, uploadFile } from "services/analysis";

import { RecentAnalysis } from "ts/analysis";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { analysisJobInProgress } from "utils/analysisJobInProgress";
import { isAnalyzed } from "utils/isAnalyzed";
import { AnalysisStatus, FileUploadStatus } from "@explorance/mly-types";
import { getLocalTime } from "utils/getLocalTime";
import { useAppDispatch } from "store";
import { showToastError } from "store/toast/toastSlice";
import { Text } from "components/Text";

type Props = {
  analysis: RecentAnalysis;
  refetchTableData: (id: number) => void;
};

export const RecentUpdateRow = ({ analysis, refetchTableData }: Props) => {
  const dispatch = useAppDispatch();
  const [isImportError, setIsImportError] = useState<boolean>(false);
  const [hoveredAnalysisTitleId, setHoveredAnalysisTitleId] = useState<number>();

  const fileInputRef = useRef<HTMLInputElement>();

  const history = useHistory();
  const getTimeStamp = useTimeStamp();

  const analysisFailed = analysis.analysisStatus === AnalysisStatus.Failed;
  const importFailed = analysis.fileUploadStatus === FileUploadStatus.Failed;
  const analysisFailedFirst =
    analysisFailed && analysis.analysisLastUpdated >= analysis.fileUploadLastUpdated;
  const importFailedFirst =
    importFailed && analysis.fileUploadLastUpdated >= analysis.analysisLastUpdated;
  const convertedLastUpdated = getLocalTime(new Date(analysis.lastUpdated));

  const onHoverTitle = (analysis: RecentAnalysis) => (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
      setHoveredAnalysisTitleId(analysis.analysisId);
    }
  };

  const onClickRow = (analysis: RecentAnalysis) =>
    analysis.analysisStatus === AnalysisStatus.Completed
      ? history.push(routes.overviewPage(analysis.analysisId))
      : history.push(routes.dataPreviewPage(analysis.analysisId));

  const handleImportAgain = async (file: File) => {
    setIsImportError(false);
    try {
      await uploadFile(analysis.analysisId, file);
      refetchTableData(analysis.analysisId);
    } catch (e) {
      setIsImportError(true);
      dispatch(
        showToastError({
          message: {
            titleKey: "toast.uploadFileFailedTitle",
            captionKey: e.toString().includes("InvalidColumnHeaders")
              ? "toast.uploadMismatchCaption"
              : "toast.importMoreFailedCaption",
          },
        })
      );
    }
  };

  const handleDeleteFailedImport = async () => {
    try {
      await deleteAnalysis(analysis.analysisId);
      refetchTableData(analysis.analysisId);
    } catch {
      dispatch(showToastError("toast.defaultError"));
    }
  };

  return (
    <>
      <StyledItem
        className="fade-in"
        onClick={() => onClickRow(analysis)}
        key={analysis.analysisId}
      >
        <StyledIconContainer>
          {isAnalyzed(analysis.analysisStatus) ? (
            <CategorizationTypeSymbol categorizationType={analysis.categorizationType} />
          ) : analysisJobInProgress(analysis) ? (
            <LoadingDots scaleSize={0.8} />
          ) : (
            <Icon type={IconType.import} color={Color.gray50} size={12} />
          )}
        </StyledIconContainer>
        <StyledAnalysisInfoContainer>
          <StyledAnalysisName
            onMouseOver={onHoverTitle(analysis)}
            onMouseOut={() => setHoveredAnalysisTitleId(null)}
            data-tip
            data-for={analysis.analysisId}
          >
            {analysis.name}
          </StyledAnalysisName>
          <StyledAnalysisDescription>
            <span>{getTimeStamp(convertedLastUpdated)}</span>
            {" • "}
            {analysis.analysisStatus === AnalysisStatus.NotAnalyzed && (
              <span>
                <Text resource="recentUpdatesTable.status.notAnalyzedYet" />
              </span>
            )}
            {analysis.analysisStatus === AnalysisStatus.InProgress && (
              <span>
                <Text resource="recentUpdatesTable.status.analyzing" />
              </span>
            )}
            {analysis.analysisStatus === AnalysisStatus.Completed && !analysis.sharedWithMe ? (
              <span>
                <Text
                  resource={{
                    key: "recentUpdatesTable.analyzedCommentsCount",
                    args: [`${analysis.analyzedCommentCount}`],
                  }}
                />
              </span>
            ) : (
              analysis.analysisStatus === AnalysisStatus.Completed && (
                <span>
                  <Text resource="recentUpdatedTable.sharedWithMe" />
                </span>
              )
            )}
            {(analysisFailedFirst || analysisFailed) && (
              <span>
                <StyledFailedSpan>
                  <Text resource="table.rowStatus.analysisFailed" />
                </StyledFailedSpan>
              </span>
            )}
            {importFailedFirst && (
              <span>
                {" • "}
                <StyledFailedSpan>
                  <Text resource="table.rowStatus.importFailed" />
                </StyledFailedSpan>
              </span>
            )}
          </StyledAnalysisDescription>
          {hoveredAnalysisTitleId && (
            <Tooltip
              tooltipId={analysis.analysisId.toString()}
              className="tooltip-file-title"
              content={analysis.name}
            />
          )}
        </StyledAnalysisInfoContainer>

        {importFailedFirst && (
          <StyledButtonOverlayContainer onClick={(e) => e.stopPropagation()}>
            <StyledImportFailedActionsContainer>
              <Button
                size={ButtonSize.sm}
                variant={ButtonVariant.outlineBlue}
                onClick={() => fileInputRef.current.click()}
              >
                <Text resource="analysisList.table.dropdown.importAgain" />
              </Button>
              <StyledDeleteButton onClick={handleDeleteFailedImport}>
                <Icon type={IconType.trash} size={14} style={{ marginLeft: 10 }} />
              </StyledDeleteButton>
            </StyledImportFailedActionsContainer>
          </StyledButtonOverlayContainer>
        )}
        {analysis.fileUploadStatus === FileUploadStatus.Completed &&
          analysis.analysisStatus === AnalysisStatus.NotAnalyzed && (
            <StyledButtonOverlayContainer>
              <Button
                variant={ButtonVariant.outlineBlue}
                size={ButtonSize.sm}
                onClick={() => history.push(routes.dataPreviewPage(analysis.analysisId))}
              >
                <Text resource="button.dataPreview" />
              </Button>
            </StyledButtonOverlayContainer>
          )}
      </StyledItem>
      <FileUploadInput
        inputFileRef={fileInputRef}
        onSelectFile={handleImportAgain}
        resetFileRef={isImportError}
      />
    </>
  );
};

const StyledButtonOverlayContainer = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  padding-left: 12px;
  background-color: ${Color.white};
  display: flex;
  opacity: 0;
  transition: 0.13s opacity;
  align-items: center;
  justify-content: center;
`;

const StyledImportFailedActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDeleteButton = styled.button`
  border: none;
  background: none;
`;

const StyledFailedSpan = styled.span`
  color: ${Color.red40};
  font-weight: bold;
`;

const StyledAnalysisInfoContainer = styled.div`
  min-width: 0;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -12px;
`;
const StyledItem = styled.div`
  &:hover ${StyledButtonOverlayContainer} {
    opacity: 1;
  }
  &:last-child {
    border-bottom: none;
  }

  position: relative;
  display: grid;
  grid-template-columns: 1fr 5fr;
  transition: background-color 300ms ease-out;

  font-size: 0.875em;
  border-bottom: 1px solid ${Color.sky40};
  padding: 10px;

  > * {
    min-width: 0;
    min-height: 0;
  }

  .failed {
    color: ${Color.red30};
  }

  .tooltip-file-title {
    max-width: 300px;
    overflow-wrap: break-word;
  }

  &:hover {
    cursor: pointer;
    background-color: ${Color.sky10};
  }
`;

const StyledAnalysisName = styled.div`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Color.gray40};
  margin-bottom: 2px;
`;

const StyledAnalysisDescription = styled.div`
  color: ${Color.gray30};
  font-size: 14px;
`;
