import { RoleType } from "@explorance/mly-types";

export const getBelowExistingUserCountError = (
  details: Record<string, any>,
  userRoleType: string
) => {
  let messageKey;
  switch (userRoleType) {
    case RoleType.SuperAdmin:
      messageKey = "licensing.quotaMenu.error.belowExistingUserCount.superAdmin";
      break;
    case RoleType.Admin:
      messageKey = "licensing.quotaMenu.error.belowExistingUserCount.admin";
      break;
    case RoleType.Analyst:
      messageKey = "licensing.quotaMenu.error.belowExistingUserCount.analyst";
      break;
    case RoleType.Viewer:
      messageKey = "licensing.quotaMenu.error.belowExistingUserCount.viewer";
  }

  const toastMessage = {
    message: {
      titleKey: messageKey,
      args: [{ titleKeyArg: details.count }],
    },
  };
  return toastMessage;
};
