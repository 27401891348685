import React, { useContext, useState } from "react";
import styled from "styled-components";
import { lowerFirst } from "lodash";

import { useResource } from "hooks/useResource";
import { validateWidgetTitle } from "utils/validation";
import useClickOutside from "hooks/useClickOutside";

import { Widget } from "ts/widget";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

import { WidgetTypeIcon } from "components/Widget/WidgetTypeIcon";
import { SectionTitle } from "components/SectionTitle";
import { Icon, IconType } from "components/_icons/Icon";
import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import {
  BreakdownTypes,
  Ranking,
  WidgetHeight,
  WidgetType,
  WidgetWidth,
} from "@explorance/mly-types";
import { WidgetSizeButton } from "./WidgetSizeButton";
import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";
import {
  getFirstBreakdownOptions,
  getRankingOptions,
  getSecondBreakdownOptions,
} from "utils/getBreakdownOptions";
import { AnalysisContext } from "context/AnalysisContext";
import { BreakdownLevel } from "ts/enums/breakdown";
import { ButtonVariant } from "ts/enums/button";
import { getStatsForWidgetType } from "utils/getStatsForWidgetType";

type Props = {
  widget: Widget;
  restrictedAccess: boolean;
  onChange(updatedWidget: Widget, hasErrors: boolean, resetStatistics?: boolean): void;
  isTopicFilterAvailable(widgetType: WidgetType, L1: BreakdownTypes, L2: BreakdownTypes): boolean;
  discardFilters(): void;
};

const inputMaxLength = 255;

export const BaseWidgetSettingsSection = ({
  widget,
  restrictedAccess,
  onChange,
  isTopicFilterAvailable,
  discardFilters,
}: Props) => {
  const [analysisState] = useContext(AnalysisContext);
  const { getResource } = useResource();
  const dropdownRef = useClickOutside(() => setShowDropdown(false));

  const [invalidTitle, setInvalidTitle] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const isSRA = analysisState.analysisDetails.graphId === null;
  const firstBreakdown = widget.configuration.breakdowns[0] ?? {
    type: null,
    ranking: Ranking.Top,
    limit: 5,
  };
  const secondBreakdown = widget.configuration.breakdowns?.[1];

  const firstBreakdownOptions = getFirstBreakdownOptions(
    widget.type,
    analysisState.availableDemographicFilters,
    isSRA,
    getResource
  );

  const secondBreakdownOptions = getSecondBreakdownOptions(
    widget.type,
    firstBreakdown.type,
    isSRA,
    getResource,
    analysisState.availableDemographicFilters,
    firstBreakdown.demographic?.name
  );

  const selectedRankingL1 = {
    label: getResource(`ranking.${firstBreakdown.ranking}`) + " " + firstBreakdown.limit,
    value: firstBreakdown.ranking + " " + firstBreakdown.limit,
  };

  const selectedRankingL2 = {
    label: secondBreakdown
      ? getResource(`ranking.${secondBreakdown?.ranking}`) + " " + secondBreakdown?.limit
      : getResource("sideDrawer.widget.defaultRanking"),
    value: secondBreakdown
      ? secondBreakdown?.ranking + " " + secondBreakdown?.limit
      : getResource("sideDrawer.widget.defaultRanking"),
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.substring(0, inputMaxLength);
    const isInvalidTitle = !validateWidgetTitle(value) || value.trim() === "";
    setInvalidTitle(isInvalidTitle);
    onChange({ ...widget, title: value }, isInvalidTitle);
  };

  const getWidgetTypeOptions = () => {
    const SRAWidgetTypes = [
      WidgetType.AllSentiments,
      WidgetType.AllRecommendations,
      WidgetType.MostAlerts,
    ];

    return isSRA
      ? Object.entries(WidgetType).filter(([, value]) => SRAWidgetTypes.includes(value))
      : Object.entries(WidgetType);
  };

  const getBreakdownLabel = (breakdownLevel: BreakdownLevel, breakdownType: BreakdownTypes) => {
    if (breakdownType && breakdownType !== BreakdownTypes.Demographics) {
      return getResource(`breakdownBy.${breakdownType}`);
    } else if (breakdownType === BreakdownTypes.Demographics) {
      return widget.configuration.breakdowns?.[breakdownLevel]?.demographic?.name;
    } else {
      return getResource("dropdown.none");
    }
  };

  const handleChangeType = (widgetType: WidgetType) => {
    if (widgetType === widget.type) {
      setShowDropdown(false);
      return;
    }

    const selectedStats = getStatsForWidgetType(widgetType, null, true)
      .filter((stat) => stat.isDefault)
      .map((stat) => stat.type);

    const updatedWidget = {
      ...widget,
      type: widgetType,
      filters: {
        ...widget.filters,
      },
      configuration: {
        ...widget.configuration,
        stats: selectedStats,
        breakdowns: [
          {
            type: getFirstBreakdownOptions(widgetType, [], isSRA, getResource)[0].value,
            ranking: Ranking.Top,
            limit: 5,
          },
          { type: null, ranking: Ranking.Top, limit: 5 },
        ],
      },
      title: getResource(`widget.${lowerFirst(widgetType)}`),
    } as Widget;
    setInvalidTitle(false);
    onChange(updatedWidget, false);
    setShowDropdown(false);
    discardFilters();
  };

  const onChangeSize = (widgetWidth: WidgetWidth) => {
    onChange(
      {
        ...widget,
        configuration: {
          ...widget.configuration,
          size: { width: widgetWidth, height: WidgetHeight.Full },
        },
      },
      false
    );
  };

  const handleChangeBreakdown = (breakdownLevel: BreakdownLevel, breakdownType: BreakdownTypes) => {
    let demographicFilter = null;

    if (breakdownType && !(Object.values(BreakdownTypes) as string[]).includes(breakdownType)) {
      demographicFilter = analysisState.availableDemographicFilters.find(
        (filter) => filter.name === breakdownType
      );
      breakdownType = BreakdownTypes.Demographics;
    }

    const l1BreakdownType =
      breakdownLevel === BreakdownLevel.L1 ? breakdownType : firstBreakdown?.type;
    const l2BreakdownType =
      breakdownLevel === BreakdownLevel.L2 ? breakdownType : secondBreakdown?.type;

    onChange(
      {
        ...widget,
        configuration: {
          ...widget.configuration,
          breakdowns: [
            ...widget.configuration.breakdowns.slice(0, breakdownLevel),
            widget.configuration.breakdowns[breakdownLevel]
              ? {
                  type: breakdownType as BreakdownTypes,
                  limit: widget.configuration.breakdowns[breakdownLevel].limit,
                  ranking: widget.configuration.breakdowns[breakdownLevel].ranking,
                  ...(demographicFilter && { demographic: demographicFilter }),
                }
              : {
                  type: breakdownType as BreakdownTypes,
                  ranking: Ranking.Top,
                  limit: 5,
                  ...(demographicFilter && { demographic: demographicFilter }),
                },
          ],
        },
      },
      false,
      !isTopicFilterAvailable(widget?.type, l1BreakdownType, l2BreakdownType)
    );
  };

  const handleChangeRanking = (breakdownLevel: BreakdownLevel, ranking: string) => {
    const parsedRanking = ranking.split(" ")[0] as Ranking;
    const parsedLimit = parseInt(ranking.split(" ")[1]);

    const updatedBreakdown = {
      ...widget.configuration.breakdowns[breakdownLevel],
      ranking: parsedRanking,
      limit: parsedLimit,
    };

    onChange(
      {
        ...widget,
        configuration: {
          ...widget.configuration,
          breakdowns: [
            ...widget.configuration.breakdowns.slice(0, breakdownLevel),
            updatedBreakdown,
            ...widget.configuration.breakdowns.slice(breakdownLevel + 1),
          ],
        },
      },
      false
    );
  };

  return (
    <>
      <StyledBaseWidgetTopSection>
        <StyledWidgetTypeContainer>
          {/* WIDGET TYPE */}
          <SectionTitle titleKey="sideDrawer.widget.widgetType.label" />
          <div ref={dropdownRef}>
            <StyledTypeDropdownContainer
              onClick={() =>
                restrictedAccess || !!widget.id ? undefined : setShowDropdown((show) => !show)
              }
              disabled={restrictedAccess || !!widget.id}
            >
              <StyledDropdownTitle disabled={restrictedAccess || !!widget.id}>
                <WidgetTypeIcon widgetType={widget.type} displayBackground={false} useSolidIcons />
                <Text resource={`widget.${lowerFirst(widget.type)}`} />
              </StyledDropdownTitle>
              <Icon type={IconType.triangleFilledDown} size={10} color={Color.gray30} />
            </StyledTypeDropdownContainer>
            <StyledWidgetTypeDropdown show={showDropdown}>
              {getWidgetTypeOptions().map(([item, value]) => {
                return (
                  <li
                    className={WidgetType[item] === widget.type ? "active" : undefined}
                    key={item}
                    onClick={() => handleChangeType(WidgetType[item])}
                  >
                    <span>
                      <WidgetTypeIcon
                        widgetType={WidgetType[item]}
                        displayBackground={false}
                        useSolidIcons
                      />
                    </span>
                    <span className={WidgetType[item] === widget.type ? "type-active" : undefined}>
                      <Text resource={`widget.${lowerFirst(value)}`} />
                    </span>
                  </li>
                );
              })}
            </StyledWidgetTypeDropdown>
          </div>
        </StyledWidgetTypeContainer>
        {/* WIDGET TITLE */}
        <StyledWidgetTitle disabled={restrictedAccess}>
          <SectionTitle titleKey="sideDrawer.widget.widgetName.label" />
          <StyledTitleInput
            type="text"
            value={widget.title}
            disabled={restrictedAccess}
            onChange={restrictedAccess ? undefined : onChangeTitle}
            className={invalidTitle ? "invalid" : undefined}
          />
        </StyledWidgetTitle>
      </StyledBaseWidgetTopSection>
      {/* BREAKDOWN SECTION */}
      <StyledWidgetBreakdownSection>
        <Select
          selectTitleKey="sideDrawer.widget.breakdown.label"
          options={firstBreakdownOptions}
          selectedOption={{
            label: getBreakdownLabel(BreakdownLevel.L1, firstBreakdown.type),
            value:
              firstBreakdown.type === BreakdownTypes.Demographics
                ? firstBreakdown.demographic?.name
                : firstBreakdown.type,
          }}
          handleChange={(breakdownType) => handleChangeBreakdown(BreakdownLevel.L1, breakdownType)}
          selectStyle={{
            margin: "12px 0px 0px 0px",
            width: firstBreakdown.type ? "68%" : "100%",
          }}
          buttonStyle={{ width: "100%" }}
          dropdownWidth="100%"
          dropdownMaxHeight="200px"
          buttonVariant={ButtonVariant.neutral}
          labelStyle={{ fontWeight: "normal", color: Color.gray50 }}
        />
        {firstBreakdown.type && (
          <Select
            selectTitleKey="sideDrawer.widget.ranking.label"
            options={getRankingOptions(getResource)}
            selectedOption={selectedRankingL1}
            handleChange={(ranking) => handleChangeRanking(BreakdownLevel.L1, ranking)}
            selectStyle={{ margin: "12px 0px 0px 0px", width: "30%" }}
            buttonStyle={{ width: "100%" }}
            dropdownWidth="100%"
            buttonVariant={ButtonVariant.neutral}
            labelStyle={{ fontWeight: "normal", color: Color.gray50 }}
          />
        )}
      </StyledWidgetBreakdownSection>
      {firstBreakdown.type && secondBreakdownOptions.length !== 0 && (
        <StyledWidgetBreakdownSection>
          <Select
            selectTitleKey="sideDrawer.widget.breakdown.and"
            options={secondBreakdownOptions}
            selectedOption={{
              label:
                getBreakdownLabel(BreakdownLevel.L2, secondBreakdown?.type) ||
                getResource("dropdown.none"),
              value:
                widget.configuration.breakdowns?.[1]?.type === BreakdownTypes.Demographics
                  ? secondBreakdown?.demographic?.name
                  : widget.configuration.breakdowns?.[1]?.type || null,
            }}
            handleChange={(breakdownType) =>
              handleChangeBreakdown(BreakdownLevel.L2, breakdownType)
            }
            selectStyle={{
              margin: "12px 0px 0px 0px",
              width: secondBreakdown?.type ? "68%" : "100%",
            }}
            dropdownWidth="100%"
            dropdownMaxHeight="200px"
            buttonStyle={{
              width: "100%",
            }}
            buttonVariant={ButtonVariant.neutral}
            labelStyle={{ fontWeight: "normal", color: Color.gray50 }}
          />
          {secondBreakdown?.type && (
            <Select
              selectTitleKey="sideDrawer.widget.ranking.label"
              options={getRankingOptions(getResource)}
              selectedOption={selectedRankingL2}
              handleChange={(ranking) => handleChangeRanking(BreakdownLevel.L2, ranking)}
              selectStyle={{ margin: "12px 0px 0px 0px", width: "30%" }}
              dropdownWidth="100%"
              buttonStyle={{ width: "100%" }}
              buttonVariant={ButtonVariant.neutral}
              labelStyle={{ fontWeight: "normal", color: Color.gray50 }}
            />
          )}
        </StyledWidgetBreakdownSection>
      )}

      {/* SIZE SECTION */}
      <SectionTitle titleKey="sideDrawer.widget.size.label" />
      <StyledWidgetSizeButtons>
        <WidgetSizeButton
          label={getResource("sideDrawer.widget.size.small")}
          widthOption={WidgetWidth.OneThird}
          widget={widget}
          onClick={onChangeSize}
          disabled={restrictedAccess}
        />
        <WidgetSizeButton
          label={getResource("sideDrawer.widget.size.medium")}
          widthOption={WidgetWidth.TwoThirds}
          widget={widget}
          onClick={onChangeSize}
          disabled={restrictedAccess}
        />
        <WidgetSizeButton
          label={getResource("sideDrawer.widget.size.large")}
          widthOption={WidgetWidth.Full}
          widget={widget}
          onClick={onChangeSize}
          disabled={restrictedAccess}
        />
      </StyledWidgetSizeButtons>
    </>
  );
};

const StyledBaseWidgetTopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledWidgetSizeButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledWidgetTypeContainer = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledWidgetTypeDropdown = styled(StyledDropdownTransition)<{ show: boolean }>`
  position: absolute;
  top: 80px;
  left: 0px;
  right: 0px;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  box-shadow: 0px 3px 6px #00000029;
  background: ${Color.white};
  display: flex;
  flex-direction: column;
  z-index: ${ZIndexStackingContext.low};
  padding: 10px 0px;

  li {
    display: flex;
    gap: 10px;
    height: 30px;
    align-items: center;
    cursor: pointer;
    background-color: ${Color.white};
    font-size: 12px;
    padding: 5px 12px;
    list-style: none;

    &:hover {
      background-color: ${Color.sky15};
    }

    &.active {
      background-color: ${Color.sky15};
    }

    span {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: ${Color.gray40};
    }
  }

  .type-active {
    font-weight: bold;
    color: ${Color.blue50};
  }
`;

const StyledWidgetBreakdownSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledWidgetTitle = styled.div<{ disabled?: boolean }>`
  width: 49%;
  position: relative;
  display: flex;
  flex-direction: column;

  input {
    height: 36px;
    width: 100%;
    border: 1px solid ${Color.blue20};
    border-radius: 2px;
    padding: 9px 12px;
    font-size: 0.875em;
    color: ${({ disabled }) => (disabled ? Color.gray20 : Color.gray50)};
    :focus {
      outline: none;
      border: 1px solid ${Color.blue50};
    }

    &.invalid {
      outline: none;
      border: 1px solid red;
    }
  }
`;

const StyledTitleInput = styled.input<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? Color.gray20 : Color.gray50)};
  background-color: ${({ disabled }) => (disabled ? Color.neutral10 : Color.white)};
`;

const StyledTypeDropdownContainer = styled.div<{ disabled: boolean }>`
  width: 100%;
  position: relative;
  border: 1px solid ${Color.blue20};
  border-radius: 2px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  display: flex;
  height: 36px;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? Color.neutral10 : Color.white)};
`;

const StyledDropdownTitle = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  font-size: 0.875em;
  color: ${({ disabled }) => (disabled ? Color.gray20 : Color.gray50)};
  svg {
    height: 15px;
    width: 15px;
    margin-right: 12px;
  }
`;
