import React, { useState } from "react";
import styled from "styled-components";
import { round } from "lodash";

import { TopicsListView, WidgetStat, WidgetType } from "@explorance/mly-types";
import { Color } from "ts/enums/color";

import { sentimentStyles } from "assets/constants/sentimentStyles";

import { TopicSummaryButton } from "components/_buttons/TopicSummaryButton";
import { SentimentIcon } from "components/_icons/SentimentIcon";
import { Icon, IconType } from "components/_icons/Icon";
import { RelevantCommentsModal } from "components/_modals/RelevantCommentsModal";
import { getStatsForWidgetType } from "utils/getStatsForWidgetType";
import { WidgetFilter } from "ts/widget";

type Props = {
  selectedStats: WidgetStat[];
  showTopicSummaryButton?: boolean;
  data: Record<string, any>;
  widgetType?: WidgetType;
  widgetFilter?: WidgetFilter;
  onClickComments?: (isDisplayedStatsCommentsClick: boolean) => void;
  isVirtual?: boolean;
};

export const DisplayedStats = ({
  data,
  widgetType,
  widgetFilter,
  selectedStats,
  showTopicSummaryButton,
  isVirtual,
  onClickComments,
}: Props) => {
  const [topicSummaryModalOpen, setTopicSummaryModalOpen] = useState<boolean>(false);

  const mandatoryStat = getStatsForWidgetType(widgetType, [], false).find(
    (stat) => stat.isMandatory
  );

  const showSentiment =
    selectedStats.includes(WidgetStat.DominantSentiment) &&
    mandatoryStat?.type !== WidgetStat.DominantSentiment;
  const showComments =
    selectedStats.includes(WidgetStat.CommentCount) &&
    mandatoryStat?.type !== WidgetStat.CommentCount;
  const showRecommendations =
    selectedStats.includes(WidgetStat.RecommendationCount) &&
    mandatoryStat?.type !== WidgetStat.RecommendationCount;
  // eslint-disable-next-line
  const showAlerts =
    selectedStats.includes(WidgetStat.AlertCount) && mandatoryStat?.type !== WidgetStat.AlertCount;

  const sentimentColor =
    sentimentStyles[data.dominantSentiment?.sentimentType]?.color || Color.black;

  const modalView = [
    WidgetType.AllRecommendations,
    WidgetType.CategorizedRecommendations,
    WidgetType.PrioritizedRecommendations,
  ].includes(widgetType)
    ? TopicsListView.Recommendations
    : TopicsListView.Sentiments;

  const topicModalData = {
    id: data.id,
    fullPath: data.fullPath,
    dominantSentiment:
      modalView === TopicsListView.Sentiments
        ? data.dominantSentiment?.sentimentType
        : data.recommendationType?.trim(),
    insights: data.insightCount,
    recommendations: data.recommendationCount,
  };

  return (
    <StyledDisplayedStats>
      {showAlerts && (
        <StyledIconValueGroup>
          <Icon type={IconType.warning} color={Color.red50} size={16} />
          <span style={{ color: Color.red50 }}>{data.alertCount}</span>
        </StyledIconValueGroup>
      )}
      {showSentiment &&
        data.dominantSentiment.sentimentType && ( // If sentiment type returned is null, do not display it (BLUEML-3674)
          <StyledIconValueGroup>
            <SentimentIcon type={data.dominantSentiment.sentimentType} />
            <span style={{ color: sentimentColor }}>
              {round(data.dominantSentiment.percentage, 2)}%
            </span>
          </StyledIconValueGroup>
        )}
      {showComments && (
        <StyledIconValueGroup
          isClickable={!!onClickComments}
          onClick={onClickComments && (() => onClickComments(true))}
        >
          <Icon type={IconType.comment} color={Color.gray30} size={13} />
          <span style={{ color: Color.gray30 }}>{data.commentCount}</span>
        </StyledIconValueGroup>
      )}
      {showRecommendations && (
        <StyledIconValueGroup>
          <Icon type={IconType.recommendation} color={Color.gray30} size={12} />
          <span style={{ color: Color.gray30 }}>{data.recommendationCount}</span>
        </StyledIconValueGroup>
      )}
      {showTopicSummaryButton && (
        <>
          <TopicSummaryButton
            onClick={() => setTopicSummaryModalOpen(true)}
            topic={topicModalData}
          />
          {widgetFilter && (
            <RelevantCommentsModal
              isOpen={topicSummaryModalOpen}
              onClose={() => setTopicSummaryModalOpen(false)}
              showSentimentFeatures={[
                WidgetType.MostPopular,
                WidgetType.CategorizedRecommendations,
              ].includes(widgetType)}
              selectedView={modalView}
              filters={{
                demographics: widgetFilter.demographics as any,
                threshold: widgetFilter.threshold,
                selectedColumns: widgetFilter.selectedColumns,
              }}
              topic={topicModalData}
              isVirtual={isVirtual}
            />
          )}
        </>
      )}
    </StyledDisplayedStats>
  );
};

const StyledDisplayedStats = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  gap: 9px;
`;

const StyledIconValueGroup = styled.div<{ isClickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;
