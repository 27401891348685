import { BreakdownTypes, Ranking, WidgetType } from "@explorance/mly-types";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { SelectOption } from "ts/select";
import { RANKING_LIMITS } from "ts/widget";

export const getRankingOptions = (getResource): SelectOption[] => {
  return Object.entries(Ranking)
    .map(([, value]) => {
      return RANKING_LIMITS.map((limit) => ({
        label: getResource(`ranking.${value}`) + " " + limit,
        value: value + " " + limit,
      }));
    })
    .flat();
};

export const getFirstBreakdownOptions = (
  widgetType: WidgetType,
  demographics: DemographicFilter[],
  isSRA: boolean,
  getResource
): SelectOption[] => {
  if (!widgetType) return [];

  const sraBreakdownOptions = {
    [WidgetType.MostPopular]: [BreakdownTypes.Demographics, BreakdownTypes.SentimentType],
    [WidgetType.AllRecommendations]: [
      BreakdownTypes.RecommendationType,
      BreakdownTypes.Demographics,
    ],
    [WidgetType.MostAlerts]: [null, BreakdownTypes.Demographics],
    [WidgetType.AllSentiments]: [BreakdownTypes.Demographics, BreakdownTypes.SentimentType],
  };

  const nonSraBreakdownOptions = {
    [WidgetType.MostPositive]: [BreakdownTypes.Topics],
    [WidgetType.WhatToImprove]: [BreakdownTypes.Topics],
    [WidgetType.PrioritizedRecommendations]: [BreakdownTypes.Topics],
    [WidgetType.MostPopular]: [
      BreakdownTypes.Topics,
      BreakdownTypes.SentimentType,
      BreakdownTypes.Demographics,
    ],
    [WidgetType.AllRecommendations]: [
      BreakdownTypes.RecommendationType,
      BreakdownTypes.Demographics,
    ],
    [WidgetType.AllSentiments]: [BreakdownTypes.SentimentType, BreakdownTypes.Demographics],
    [WidgetType.MostAlerts]: [null, BreakdownTypes.Demographics],
    [WidgetType.CategorizedRecommendations]: [
      BreakdownTypes.Topics,
      BreakdownTypes.RecommendationType,
      BreakdownTypes.Demographics,
    ],
  };

  const breakdownOptions = isSRA ? sraBreakdownOptions : nonSraBreakdownOptions;

  return breakdownOptions[widgetType]
    .map((type) => {
      if (type !== BreakdownTypes.Demographics) {
        return {
          label: getResource(`breakdownBy.${type}`),
          value: type,
        };
      } else {
        return demographics.map((demographic) => ({
          label: demographic.name,
          value: demographic.name,
        }));
      }
    })
    .flat();
};

export const getSecondBreakdownOptions = (
  widgetType: WidgetType,
  firstBreakdown: BreakdownTypes,
  isSRA: boolean,
  getResource,
  demographics: DemographicFilter[],
  selectedDemographic?: string
): SelectOption[] => {
  if (!widgetType || !firstBreakdown) return [];

  const validCombinations = isSRA
    ? {
        [WidgetType.MostPopular]: {
          [BreakdownTypes.SentimentType]: [BreakdownTypes.Demographics],
          [BreakdownTypes.Demographics]: [BreakdownTypes.Demographics],
        },
        [WidgetType.AllRecommendations]: {
          [BreakdownTypes.RecommendationType]: [null, BreakdownTypes.Demographics],
          [BreakdownTypes.Demographics]: [
            null,
            BreakdownTypes.RecommendationType,
            BreakdownTypes.Demographics,
          ],
        },
        [WidgetType.MostAlerts]: {
          [BreakdownTypes.Demographics]: [null, BreakdownTypes.Demographics],
        },
        [WidgetType.AllSentiments]: {
          [BreakdownTypes.SentimentType]: [null, BreakdownTypes.Demographics],
          [BreakdownTypes.Demographics]: [
            null,
            BreakdownTypes.SentimentType,
            BreakdownTypes.Demographics,
          ],
        },
      }
    : {
        [WidgetType.MostAlerts]: {
          [BreakdownTypes.Demographics]: [null, BreakdownTypes.Demographics],
        },
        [WidgetType.MostPopular]: {
          [BreakdownTypes.Topics]: [
            null,
            BreakdownTypes.SentimentType,
            BreakdownTypes.Demographics,
          ],
          [BreakdownTypes.SentimentType]: [
            null,
            BreakdownTypes.Topics,
            BreakdownTypes.Demographics,
          ],
          [BreakdownTypes.Demographics]: [
            null,
            BreakdownTypes.Topics,
            BreakdownTypes.SentimentType,
            BreakdownTypes.Demographics,
          ],
        },
        [WidgetType.MostPositive]: {
          [BreakdownTypes.Topics]: [BreakdownTypes.Demographics],
        },
        [WidgetType.PrioritizedRecommendations]: {
          [BreakdownTypes.Topics]: [null, BreakdownTypes.Demographics],
        },
        [WidgetType.AllRecommendations]: {
          [BreakdownTypes.RecommendationType]: [null, BreakdownTypes.Demographics],
          [BreakdownTypes.Demographics]: [
            null,
            BreakdownTypes.RecommendationType,
            BreakdownTypes.Demographics,
          ],
        },
        [WidgetType.WhatToImprove]: {
          [BreakdownTypes.Topics]: [BreakdownTypes.Demographics],
        },
        [WidgetType.AllSentiments]: {
          [BreakdownTypes.SentimentType]: [null, BreakdownTypes.Demographics],
          [BreakdownTypes.Demographics]: [
            null,
            BreakdownTypes.SentimentType,
            BreakdownTypes.Demographics,
          ],
        },
        [WidgetType.CategorizedRecommendations]: {
          [BreakdownTypes.Topics]: [
            null,
            BreakdownTypes.RecommendationType,
            BreakdownTypes.Demographics,
          ],
          [BreakdownTypes.RecommendationType]: [
            null,
            BreakdownTypes.Topics,
            BreakdownTypes.Demographics,
          ],
          [BreakdownTypes.Demographics]: [
            null,
            BreakdownTypes.Topics,
            BreakdownTypes.RecommendationType,
            BreakdownTypes.Demographics,
          ],
        },
      };

  return validCombinations[widgetType][firstBreakdown]
    ?.map((type) => {
      if (type !== BreakdownTypes.Demographics) {
        return {
          label: getResource(`breakdownBy.${type}`),
          value: type,
        };
      } else {
        return demographics
          .filter((dem) => dem.name !== selectedDemographic)
          .map((demographic) => ({
            label: demographic.name,
            value: demographic.name,
          }));
      }
    })
    .flat();
};

export const isTopicFilterAvailable = (
  widgetType: WidgetType,
  L1: BreakdownTypes,
  L2: BreakdownTypes
) => {
  const validCombinations = {
    [WidgetType.CategorizedRecommendations]: {
      [BreakdownTypes.Topics]: [
        null,
        BreakdownTypes.RecommendationType,
        BreakdownTypes.Demographics,
      ],
      [BreakdownTypes.RecommendationType]: [BreakdownTypes.Topics],
      [BreakdownTypes.Demographics]: [BreakdownTypes.Topics],
    },
    [WidgetType.MostPopular]: {
      [BreakdownTypes.Topics]: [null, BreakdownTypes.SentimentType, BreakdownTypes.Demographics],
      [BreakdownTypes.SentimentType]: [BreakdownTypes.Topics],
      [BreakdownTypes.Demographics]: [BreakdownTypes.Topics],
    },
    [WidgetType.MostPositive]: {
      [BreakdownTypes.Topics]: [null, BreakdownTypes.Demographics],
      [BreakdownTypes.Demographics]: [BreakdownTypes.Topics],
    },
    [WidgetType.WhatToImprove]: {
      [BreakdownTypes.Topics]: [null, BreakdownTypes.Demographics],
      [BreakdownTypes.Demographics]: [BreakdownTypes.Topics],
    },
    [WidgetType.PrioritizedRecommendations]: {
      [BreakdownTypes.Topics]: [null, BreakdownTypes.Demographics],
    },
  };

  return validCombinations[widgetType]?.[L1]?.includes(L2) || false;
};

export const isRecommendationTypeFilterAvailable = (
  widgetType: WidgetType,
  L1: BreakdownTypes,
  L2: BreakdownTypes
) => {
  const validCombinations = {
    [WidgetType.CategorizedRecommendations]: {
      [BreakdownTypes.Topics]: [BreakdownTypes.RecommendationType],
      [BreakdownTypes.RecommendationType]: [
        null,
        BreakdownTypes.Topics,
        BreakdownTypes.Demographics,
      ],
      [BreakdownTypes.Demographics]: [BreakdownTypes.RecommendationType],
    },
    [WidgetType.AllRecommendations]: {
      [BreakdownTypes.RecommendationType]: [null, BreakdownTypes.Demographics],
      [BreakdownTypes.Demographics]: [BreakdownTypes.RecommendationType],
    },
  };

  return validCombinations[widgetType]?.[L1]?.includes(L2) || false;
};

export const isSentimentFilterAvailable = (
  widgetType: WidgetType,
  L1: BreakdownTypes,
  L2: BreakdownTypes
) => {
  const validCombinations = {
    [WidgetType.MostPopular]: {
      [BreakdownTypes.Topics]: [BreakdownTypes.SentimentType],
      [BreakdownTypes.SentimentType]: [null, BreakdownTypes.Topics, BreakdownTypes.Demographics],
      [BreakdownTypes.Demographics]: [BreakdownTypes.SentimentType],
    },
    [WidgetType.AllSentiments]: {
      [BreakdownTypes.SentimentType]: [null, BreakdownTypes.Demographics],
      [BreakdownTypes.Demographics]: [BreakdownTypes.SentimentType],
    },
  };

  return validCombinations[widgetType]?.[L1]?.includes(L2) || false;
};
