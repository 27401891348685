import React from "react";
import styled from "styled-components";

import { L1 } from "./L1";
import { L2 } from "./L2";

import {
  BreakdownTypes,
  DemographicFilterMethod,
  WidgetStat as SelectedWidgetStat,
  SentimentType,
  WidgetType,
  WidgetWidth,
} from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { WidgetStatistic } from "ts/widgetStatistics";
import { HandleClickCommentsParams, HandleClickTopicsParams } from "..";
import { getViewFromWidgetClick, l1ClickNavigation } from "./L1/helpers";
import { WidgetFilter } from "ts/widget";

type Props = {
  data: WidgetStatistic;
  l1Type: BreakdownTypes;
  isVirtual: boolean;
  widgetType: WidgetType;
  widgetWidth: WidgetWidth;
  widgetFilter: WidgetFilter;
  selectedStats: SelectedWidgetStat[];
  canAccessTopics: boolean;
  canAccessComments: boolean;
  onClickTopics: (params: HandleClickTopicsParams, resetThreshold?: boolean) => void;
  onClickComments: (params: HandleClickCommentsParams, resetThreshold?: boolean) => void;
};

export const WidgetStat = ({
  data: l1Data,
  l1Type,
  isVirtual,
  onClickTopics,
  onClickComments,
  widgetType,
  selectedStats,
  widgetWidth,
  widgetFilter,
  canAccessTopics,
  canAccessComments,
}: Props) => {
  const l1DemographicData = {
    id: l1Data.id,
    method: DemographicFilterMethod.List,
    name: l1Data.name,
    listValues: [l1Data.value],
    totalCount: l1Data.commentCount,
    searchValue: [],
  };

  const fixedStatistic = {
    ...(widgetType === WidgetType.MostPositive && { stats: [SentimentType.positive] }),
    ...(widgetType === WidgetType.WhatToImprove && { stats: [SentimentType.negative] }),
  };

  const onClickL1Statistic = (isDisplayedStatsCommentsClick?: boolean) => {
    const topicFilter = {
      id: l1Data.id,
      parentId: l1Data.parentId,
      fullPath: l1Data.fullPath,
    };
    switch (l1Type) {
      case BreakdownTypes.Topics: {
        onClickComments({
          topic: [topicFilter],
          ...(l1Data?.recommendationType && { stats: [l1Data.recommendationType] }),
          ...(!isDisplayedStatsCommentsClick && fixedStatistic),
          ...(widgetFilter?.statistics?.length > 0 && { stats: widgetFilter.statistics }),
          view: getViewFromWidgetClick({ widgetType, l1BreakdownType: l1Type }),
        });
        return;
      }
      case BreakdownTypes.SentimentType: {
        return onClickComments({
          stats: [l1Data.dominantSentiment.sentimentType],
          view: getViewFromWidgetClick({ widgetType, l2BreakdownType: l1Data.details?.type }),
        });
      }
      case BreakdownTypes.Demographics: {
        return onClickComments({
          breakdownDemographics: [l1DemographicData],
          ...(widgetFilter?.statistics?.length > 0 && { stats: widgetFilter.statistics }),
          view: getViewFromWidgetClick({ widgetType, l1BreakdownType: l1Type }),
        });
      }
      case BreakdownTypes.RecommendationType: {
        return onClickComments({
          stats: [l1Data.name],
          view: getViewFromWidgetClick({ widgetType, l1BreakdownType: l1Type }),
        });
      }
    }
  };

  const canClickMetric =
    (l1Type === BreakdownTypes.Topics && canAccessTopics && !!onClickTopics) ||
    (l1Type !== BreakdownTypes.Topics && canAccessComments && !!onClickComments);

  return (
    <StyledWidgetStat>
      <L1
        breakdownType={l1Type}
        isVirtual={isVirtual}
        onClickMetric={
          canClickMetric
            ? () =>
                l1ClickNavigation(
                  l1Data,
                  l1Type,
                  [l1DemographicData],
                  widgetType,
                  fixedStatistic,
                  widgetFilter,
                  onClickComments,
                  onClickTopics
                )
            : undefined
        }
        onClickL1Statistic={onClickComments ? onClickL1Statistic : undefined}
        onClickCommentsIcon={onClickL1Statistic}
        data={l1Data}
        selectedStats={selectedStats}
        widgetWidth={widgetWidth}
        widgetType={widgetType}
        widgetFilter={widgetFilter}
        canAccessComments={canAccessComments}
      />
      {l1Data.details && (
        <L2
          l1Data={l1Data}
          l1BreakdownType={l1Type}
          l1DemographicData={[l1DemographicData]}
          widgetWidth={widgetWidth}
          widgetType={widgetType}
          isVirtual={isVirtual}
          onClickComments={
            canAccessComments ? (params) => onClickComments(params, true) : undefined
          }
          onClickTopics={canAccessTopics ? (params) => onClickTopics(params, true) : undefined}
        />
      )}
    </StyledWidgetStat>
  );
};

const StyledWidgetStat = styled.div`
  border: 1px solid ${Color.blue20};
  border-radius: 5px;
  padding: 12px;
`;
