import React from "react";
import { round } from "lodash";
import styled from "styled-components";
import {
  BreakdownTypes,
  RecommendationType,
  SentimentType,
  WidgetType,
  WidgetWidth,
} from "@explorance/mly-types";
import { useResource } from "hooks/useResource";

import { TopicPathLabel } from "../L1/TopicPathLabel";
import { getTranslatedFullPath } from "../L1/helpers";
import { getRandomNumber } from "utils/getRandomNumber";
import { l2NavigationHandler } from "./l2NavigationHelpers";

import { Color } from "ts/enums/color";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { TextType } from "ts/TextType";
import { TextTruncator } from "components/TextTruncator";
import { WidgetStatistic, WidgetStatisticDatum } from "ts/widgetStatistics";
import { HandleClickCommentsParams, HandleClickTopicsParams } from "components/Widget";
import { DemographicFilter } from "ts/filters/demographicFilter";
import { sentimentStyles } from "assets/constants/sentimentStyles";
import { RecommendationIcon } from "components/_icons/RecommendationIcon";
import { SentimentIcon } from "components/_icons/SentimentIcon";

type BarData = {
  id?: string;
  label: TextType;
  value: number;
  isPercentage: boolean;
  isOthers?: boolean;
  fullPath: string;
  demographicValue: string;
  name: string;
};

type Props = {
  l1Data: WidgetStatistic;
  l1BreakdownType: BreakdownTypes;
  l1DemographicData?: DemographicFilter[];
  widgetWidth: WidgetWidth;
  isVirtual: boolean;
  widgetType: WidgetType;
  onClickComments: (params: HandleClickCommentsParams) => void;
  onClickTopics: (params: HandleClickTopicsParams) => void;
};

export const L2 = ({
  l1Data,
  l1BreakdownType,
  l1DemographicData,
  widgetWidth,
  isVirtual,
  widgetType,
  onClickComments,
  onClickTopics,
}: Props) => {
  const { getResource } = useResource();
  const l2BreakdownType = l1Data.details.type;
  const l2Data = l1Data.details.data;

  // UI dispatchers
  const getBarLabel = (value: string, fullPath: string, isOthers: boolean): TextType => {
    if (isOthers) return <Text resource="widget.others" />;
    switch (l2BreakdownType) {
      case BreakdownTypes.Demographics:
        return value;
      case BreakdownTypes.Topics: {
        const translatedFullPath = getTranslatedFullPath(fullPath, isVirtual, getResource);
        return <TopicPathLabel topics={translatedFullPath} widgetWidth={widgetWidth} />;
      }
      case BreakdownTypes.RecommendationType:
        return <Text resource={`recommendationPill.${value}`} />;
      case BreakdownTypes.SentimentType:
        return <Text resource={`statTab.${value}`} />;
      default:
        return value;
    }
  };

  // Data transformations
  const sumOfAllValues = l2Data.reduce((acc, curr) => acc + (curr.count ?? curr.percentage), 0);

  const getBarchartData = (data: WidgetStatisticDatum[]): BarData[] => {
    return data.map((d) => ({
      id: d.id ?? getRandomNumber(),
      label: getBarLabel(d.value, d.fullpath, d.isOthers),
      count: d.count,
      fullPath: d.fullpath,
      value: d.count ?? d.percentage,
      demographicValue: d.value,
      isPercentage: typeof d.percentage !== "undefined",
      isOthers: d.isOthers ?? false,
      name: d.name,
    }));
  };

  const getBarColor = (value: SentimentType, isOthers: boolean) => {
    if (isOthers) return Color.gray20;

    if (
      value &&
      [BreakdownTypes.RecommendationType, BreakdownTypes.SentimentType].includes(l2BreakdownType)
    ) {
      return sentimentStyles[value].color;
    }

    switch (widgetType) {
      case WidgetType.MostPositive:
      case WidgetType.WhatToImprove:
      case WidgetType.AllSentiments:
      case WidgetType.CategorizedRecommendations:
      case WidgetType.PrioritizedRecommendations:
      case WidgetType.AllRecommendations:
      case WidgetType.MostPopular:
        return Color.blue50;
      case WidgetType.MostAlerts:
        return Color.red50;
      default:
        return Color.black;
    }
  };

  const getValueIcon = (type: SentimentType | RecommendationType) => {
    switch (l2BreakdownType) {
      case BreakdownTypes.RecommendationType:
        return RecommendationIcon({ type: type as RecommendationType });
      case BreakdownTypes.SentimentType:
        return SentimentIcon({ type: type as SentimentType });
    }

    switch (widgetType) {
      case WidgetType.MostAlerts:
        return <Icon type={IconType.warning} color={Color.red50} size={12} />;
      case WidgetType.CategorizedRecommendations:
      case WidgetType.PrioritizedRecommendations:
      case WidgetType.AllRecommendations:
        return <Icon type={IconType.recommendation} color={Color.gray50} size={12} />;
      case WidgetType.AllSentiments:
      case WidgetType.MostPopular:
        return <Icon type={IconType.comment} size={12} />;
      default:
        return null;
    }
  };

  const getCountText = (label: RecommendationType, numericValue: number, isPercentage: boolean) => {
    const displayedValue = isPercentage ? `${round(numericValue)}%` : numericValue;

    if (
      label &&
      [BreakdownTypes.RecommendationType, BreakdownTypes.SentimentType].includes(l2BreakdownType)
    ) {
      return <span style={{ color: sentimentStyles[label].color }}>{displayedValue}</span>;
    }

    switch (widgetType) {
      case WidgetType.MostPositive:
      case WidgetType.WhatToImprove:
        return <span style={{ color: Color.gray50 }}>{displayedValue}</span>;
      case WidgetType.MostAlerts:
        return <span style={{ color: Color.red50 }}>{displayedValue}</span>;
      default:
        return displayedValue;
    }
  };

  const isRowClickable = (() => {
    const l2BreakdownType = l1Data.details.type;
    if (l2BreakdownType === BreakdownTypes.Topics) {
      return !!onClickTopics;
    }
    return !!onClickComments;
  })();

  return (
    <>
      <StyledBarchart>
        {getBarchartData(l2Data).map(
          (
            { id, label, value, isPercentage, isOthers, fullPath, name, demographicValue },
            index
          ) => (
            <StyledBarchartRow key={`key-${id}-${index}`} isEven={index % 2 === 0}>
              <StyledLabelAndBarSection
                widgetWidth={widgetWidth}
                isOthers={l2Data[index].isOthers}
                isClickable={isRowClickable}
                onClick={
                  isRowClickable
                    ? () =>
                        l2NavigationHandler({
                          id: Number(id),
                          fullPath,
                          index,
                          widgetType,
                          l1Data,
                          l1BreakdownType,
                          l1DemographicData,
                          demographicValue,
                          name,
                          value,
                          isL2LabelOrBarClicked: true,
                          onClickComments,
                          onClickTopics,
                        })
                    : undefined
                }
              >
                <TextTruncator id={Number(id)} value={label ?? fullPath} customWidth="94%" />
                <StyledBarSection>
                  <StyledBar
                    filledBarRatio={value / sumOfAllValues}
                    barColor={getBarColor(l2Data[index]?.value, isOthers)}
                  />
                </StyledBarSection>
              </StyledLabelAndBarSection>
              <StyledValueSection
                isOthers={l2Data[index].isOthers}
                isClickable={
                  [WidgetType.CategorizedRecommendations, WidgetType.MostPopular].includes(
                    widgetType
                  )
                    ? !!onClickComments
                    : isRowClickable
                }
                onClick={() =>
                  l2NavigationHandler({
                    id: Number(id),
                    fullPath,
                    index,
                    widgetType,
                    l1Data,
                    l1BreakdownType,
                    l1DemographicData,
                    demographicValue,
                    name,
                    value,
                    onClickComments,
                    onClickTopics,
                  })
                }
              >
                {getValueIcon(demographicValue as SentimentType | RecommendationType)}
                {getCountText(demographicValue as RecommendationType, value, isPercentage)}
              </StyledValueSection>
            </StyledBarchartRow>
          )
        )}
      </StyledBarchart>
    </>
  );
};

const StyledBarchart = styled.div`
  margin-top: 8px;
`;

const StyledBarchartRow = styled.div<{
  isEven?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  background-color: ${({ isEven }) => (isEven ? Color.white : Color.neutral10)};
  height: 30px;
  font-size: 14px;
`;

const StyledLabelAndBarSection = styled.div<{
  widgetWidth: WidgetWidth;
  isOthers: boolean;
  isClickable: boolean;
}>`
  display: grid;
  align-items: center;
  cursor: ${({ isOthers, isClickable }) => (isClickable && !isOthers ? "pointer" : "default")};
  grid-template-columns: ${({ widgetWidth }) =>
    widgetWidth === WidgetWidth.OneThird
      ? "5fr 8.5fr"
      : widgetWidth === WidgetWidth.TwoThirds
      ? "8fr 9.5fr"
      : "8fr 10fr"};

  width: ${({ widgetWidth }) =>
    widgetWidth === WidgetWidth.OneThird
      ? "80%"
      : widgetWidth === WidgetWidth.TwoThirds
      ? "90%"
      : "98%"};

  @media (max-width: 1366px) {
    grid-template-columns: ${({ widgetWidth }) =>
      widgetWidth === WidgetWidth.OneThird ? "8fr 9fr" : "7fr 10fr"};
  }
`;

const StyledBarchartCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledBarSection = styled(StyledBarchartCell)`
  border-left: 1px dashed ${Color.neutral50};
`;

const StyledBar = styled.div<{ filledBarRatio: number; barColor: string }>`
  width: 100%;
  height: 12px;
  border-radius: 0 5px 5px 0;
  background: ${({ barColor, filledBarRatio }) =>
    `linear-gradient(90deg, ${barColor} 0 ${filledBarRatio * 100}%, ${Color.blue20} ${
      filledBarRatio * 100
    }% 100%)`};
`;

const StyledValueSection = styled(StyledBarchartCell)<{ isOthers: boolean; isClickable: boolean }>`
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  width: 14%;
  cursor: ${({ isOthers, isClickable }) => (isOthers || !isClickable ? "default" : "pointer")};
`;
